<!-- Note: Keep two projects in one row. There is no difference between the left project and the right project in the row -->
<div class="container">

  <div class="row">
    <h1>Projects</h1>
  </div>



  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\QuickPass_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>QuickPass Password Manager Web App</h2>
          <p>An easy-to-use password manager that I created as a side project. Responsive on phone, tablet and computer
            screens.
            Made with Angular, HTML, CSS, and Firebase.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://quickpass-4ed21.firebaseapp.com" target="_blank"> <i class="fas fa-link fa-2x icons"
                  matTooltip="Interact with the live web app"></i> </a>
              <a href="https://github.com/ilker-h/QuickPass" target="_blank"> <i class="fab fa-github fa-2x icons"
                  matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/381806818" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\BIT_Data_Analytics_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>BIT Data Analytics Web App for Manufacturing Machines</h2>
          <p>This helps machine operators analyze data and configure tests for FANUC manufacturing machines.
            Made with MongoDB, ExpressJS, Angular, NodeJS and FANUC's MT-Linki API.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://vimeo.com/450177340" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>



  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\MentorConnect_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>MentorConnect: Networking <br> Web App</h2>
          <p>A platform for connecting mentors and mentees. It's a full stack web app made with an ASP.NET backend, an Angular frontend,
             MS SQL, and MS Azure hosting. I created it for a Udemy course.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://mentor-connect.azurewebsites.net/" target="_blank"> <i class="fas fa-link fa-2x icons"
                  matTooltip="Interact with the live web app"></i> </a>
              <a href="https://github.com/ilker-h/MentorConnect" target="_blank"> <i class="fab fa-github fa-2x icons"
                  matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/957525974" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Capstone_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Capstone Project: Virtual Tour of the Learning Factory</h2>
          <p>A Virtual Reality (VR) tour that lets a user explore the equipment and machines in McMaster University's
            Learning Factory in a 3D environment.
            Made with Unity game engine, Angular & Firebase.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://learning-factory-vr-tour.firebaseapp.com/" target="_blank"> <i
                  class="fas fa-link fa-2x icons" matTooltip="Interact with the live web app"></i> </a>
              <a href="https://vimeo.com/495898892" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>



  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Coral_Catastrophe_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Coral Catastrophe Unity Game</h2>
          <p>A Unity game I made where you play as a fish and your goal is to avoid swimming into coral. It's similar to
            the concept of the game "Flappy Bird".</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/Coral-Catastrophe" target="_blank"> <i
                  class="fab fa-github fa-2x icons" matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/381808251" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Portfolio_Manager_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Hackathon: Portfolio Manager App</h2>
          <p>Helps the user manage their financial portfolio, with real-time data from Yahoo Finance API. Made with
            Angular, Springboot (Java framework), MySQL, and Jenkins/Docker in a 3-person group.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/PortfolioManager-Hackathon" target="_blank"> <i
                  class="fab fa-github fa-2x icons" matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/593492344" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>



  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\My_Blog_App_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>My Blog Web App</h2>
          <p>A MEAN (MongoDB, ExpressJS, Angular, NodeJS) stack web app for creating blog posts. I created it for a
            Udemy course.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/My-Blog-App" target="_blank"> <i class="fab fa-github fa-2x icons"
                  matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/381809538" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Course_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>University Software Assignments</h2>
          <p>9 different assignments made using PHP, MySQL, HTML, and CSS. These were individual assignments, not group
            work.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://vimeo.com/415729502" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>



  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\WordPress_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>My WordPress Websites</h2>
          <p>This is my portfolio of WordPress websites that I created for various clients. I was the sole website
            designer on these projects.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/WordPress-Websites" target="_blank"> <i
                  class="fab fa-github fa-2x icons" matTooltip="GitHub (my code for this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Galaxy_Shooter_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Galaxy Shooter Unity Game</h2>
          <p>This is a Unity game where you play as a spaceship fighting enemies, collecting various power-ups along the
            way. I created it for a Udemy course.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/Galaxy-Shooter" target="_blank"> <i class="fab fa-github fa-2x icons"
                  matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/381810219" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>



  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\GrowLife_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>GrowLife: Environment Monitoring for Indoor Plants</h2>
          <p>An embedded systems project that helps notify users when their indoor plants are not getting enough light
            or humidity.
            Made with LoRa boards, PlatformIO, ThingSpeak API, and Twitter API.
          </p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/GrowLife-Plant_Environment_Monitoring" target="_blank"> <i
                  class="fab fa-github fa-2x icons" matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/495902592" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Recipe_Grocery_App_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Recipe and Grocery List Web App</h2>
          <p>An Angular-Firebase web app for keeping track of ingredients for recipes and grocery lists. I created it
            for a Udemy course.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://github.com/ilker-h/Recipe-and-Grocery-List-App" target="_blank"> <i
                  class="fab fa-github fa-2x icons" matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/381808925" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>




  <!-- ------------New Row------------ -->



  <div class="row" style="margin: 0px auto;">

    <!-- Left Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\Programming_Challenge_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Programming Challenge: LED Game</h2>
          <p>This is a programming challenge that was given for my "Embedded Systems" course. I had one partner. It was
            written in C
            using the Renesas e² studio IDE on the Renesas Synergy FPGA Board.</p>
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://vimeo.com/418608315" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Left Project End -->

    <!-- Right Project Start -->
    <div class="col-12 col-xl-6 overall-container max-width">
      <div class="row container-styling">

        <div class="col-12 col-sm-4 image">
          <img src="assets\CLEANR_Logo.png" height="150">
        </div>

        <div class="col-12 col-sm-8 description-and-button-and-icons">
          <h2>Cleanr IoT Project</h2>
          <p>An Internet of Things (IoT) system that provides insightful analytics on washroom hygiene. This was a group
            project for an IoT course.
            Made with Angular, Arduino MKR1000, Raspberry Pi 4, Google AppScript, HiveMQ Broker, NodeJS, and more.
          <div class="row icon-container">
            <div style="width: 100%">
              <a href="https://timetowashyourhands.web.app/" target="_blank"> <i class="fas fa-link fa-2x icons"
                  matTooltip="Interact with the live web app"></i> </a>
              <a href="https://github.com/ilker-h/CLEANR-IoT-Project" target="_blank"> <i
                  class="fab fa-github fa-2x icons" matTooltip="GitHub (my code for this project)"></i> </a>
              <a href="https://vimeo.com/415723243" target="_blank"> <i class="fab fa-vimeo fa-2x icons"
                  matTooltip="Vimeo (a video walkthrough of this project)"></i> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Right Project End -->

  </div>

  <!-- If you have an uneven number of projects, add this filler section to the end:

      <div class="col-12 col-xl-6 overall-container max-width hide-last-empty-project">
          <div class="row">

              <div class="col-12 col-sm-4 image">
                <img src="" height="150">
              </div>

              <div class="col-12 col-sm-8 description-and-button-and-icons">
                  <h2></h2>
                  <p></p>
                  <div class="row icon-container">
                      <div style="width: 100%">
                        <a href="" target="_blank"> <i></i> </a>
                      </div>
                  </div>
              </div>

          </div>
      </div>

    -->

</div>
