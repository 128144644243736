<div class="container-fluid">
  <div class="row">
    <!-- Navbar -->
    <mat-toolbar>

        <h1 [routerLink]="['/']" fragment="home">Ilker Hadzhalaran</h1>
        <span class="spacer"></span>

        <div class="hide-when-collides-with-logo">
          <a class="menu-item" [routerLink]="['/']" fragment="about">About</a>
          <a class="menu-item" [routerLink]="['/']" fragment="skills">Skills</a>
          <a class="menu-item" [routerLink]="['/']" fragment="experience">Experience</a>
          <a class="menu-item" [routerLink]="['/']" fragment="projects" pageScroll>Projects</a>
          <a class="menu-item" [routerLink]="['/']" fragment="testimonials" pageScroll>Testimonials</a>
          <a class="menu-item" [routerLink]="['/']" fragment="contact">Contact</a>
        </div>

        <!-- Hamburger Menu -->
        <div class="hamburger-menu-shown-on-small-screens">
            <button mat-icon-button class="hamburger-menu-icon" matTooltip="Menu" [matMenuTriggerFor]="hamburgerMenu">
              <mat-icon [inline]="true" style="font-size: 35px">menu</mat-icon>
            </button>

            <mat-menu #hamburgerMenu="matMenu">
              <button style="margin-right: 50px" mat-menu-item [routerLink]="['/']" fragment="about">About</button>
              <button style="margin-right: 50px" mat-menu-item [routerLink]="['/']" fragment="skills">Skills</button>
              <button style="margin-right: 50px" mat-menu-item [routerLink]="['/']" fragment="experience">Experience</button>
              <button style="margin-right: 50px" mat-menu-item [routerLink]="['/']" fragment="projects">Projects</button>
              <button style="margin-right: 50px" mat-menu-item [routerLink]="['/']" fragment="testimonials">Testimonials</button>
              <button style="margin-right: 50px" mat-menu-item [routerLink]="['/']" fragment="contact">Contact</button>
            </mat-menu>
        </div>
        <!-- /Hamburger Menu -->

    </mat-toolbar>
    <!-- /Navbar -->

  </div>
</div>
