<div class="container">

  <div class="row">
    <h1>Experience</h1>
  </div>

  <div class="row">
    <button mat-flat-button (click)="downloadFile()">Download Resume</button>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">Software Developer</h3>
    <div class="spacer"></div>
    <h3>Dec 2024 - Present</h3>
    <hr>
  </div>

  <div class="row image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\TD-Bank_Logo.png" height="200">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <ul>
        <li>Build new and enhance existing reports' UIs and APIs.</li>
        <li>Analyze existing applications and assist in planning and design for cloud migration.</li>
        <li>Use Angular, Java/Spring Boot, MS SQL Server technologies.</li>
      </ul>
    </div>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">Software Developer (Full-time, 2 Years 11 Months)</h3>
    <div class="spacer"></div>
    <h3>Jul 2021 - May 2024</h3>
    <hr>
  </div>

  <div class="row" id="Citi-image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\Citi_Logo.png" height="110">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <h4><strong>Velocity Team | Jul, 2022 – May, 2024 (1 yr 11 mo) </strong></h4>
      <ul>
        <li>Worked on sizeable enterprise codebase (310k+ lines) for a trading platform. Used Angular, WPF (C#, XAML,
          .NET), Caplin Liberator.</li>
        <li>Work included building new features (e.g., migrating legacy screens from WPF to Angular, creating UI
          components, updating trading calculations, AG Grid customizations), fixing bugs & debugging data flow issues,
          reducing technical debt, developing unit tests, and more.</li>
        <li>Led releases to production using TeamCity, IBM uDeploy, Jenkins and OpenShift. Coordinated with teams
          globally (e.g., QA, Support, BA, Development, Change Management, and more).</li>
        <li>Resolved time-sensitive issues, such as users’ Support queries, production issues, dependency
          vulnerabilities, etc.</li>
      </ul>

      <h4><strong>OneBook Team | Jul, 2021 – Jun, 2022 (1 yr)</strong></h4>
      <ul>
        <li>Worked on a new book building platform using Angular, wrote tests in Jasmine/Karma, and interacted with data
          using Java/Spring Boot and MongoDB.</li>
        <li><u>Major Project:</u> Created 2900+ line Angular feature to streamline process of updating user permissions.
          Business users can now use a GUI instead of having to ask developers to update data manually.</li>
        <li>Joined the team a few months before their first software release and completed 50+ improvements and bug
          fixes.</li>
      </ul>
    </div>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">Software Developer (4 Month Internship)</h3>
    <div class="spacer"></div>
    <h3>May 2020 - Aug 2020</h3>
    <hr>
  </div>

  <div class="row image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\MMRI_Logo.jpg" height="200">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <ul>
        <li>Created data visualization, analytics and test software for FANUC manufacturing machines in a Windows
          environment</li>
        <li>Developed with MongoDB, ExpressJS, Angular, NodeJS (MEAN stack), FANUC’s MT-Linki API and third-party
          libraries</li>
        <li>Designed app architecture, used Atlassian Suite workflows, and performed DevOps duties, including managing
          deployments</li>
      </ul>
    </div>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">C++ Programming Tutor (Part-time, 4 Months)</h3>
    <div class="spacer"></div>
    <h3>Jan - Apr 2020</h3>
    <hr>
  </div>

  <div class="row image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\McMaster_University_Logo.png" height="130">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <ul>
        <li>Tutored a first-year student in his "Object-Oriented Programming" course</li>
        <li>Provided guidance throughout the student's labs and course projects</li>
      </ul>
    </div>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">Software Developer (8 Month Co-op)</h3>
    <div class="spacer"></div>
    <h3>Jan - Aug 2019</h3>
    <hr>
  </div>

  <div class="row image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\APT_Logo.png" height="150">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <ul>
        <li>Worked on developing a web application for helping users manage different versions of their 3D model files</li>
        <li>Used Angular, NodeJS, HTML, CSS, and MongoDB in a Linux environment</li>
        <li>Worked with third-party libraries to create features (e.g., PDF viewer)</li>
        <li>Contributed to various types of documentation (e.g., SRS document)</li>
      </ul>
    </div>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">Product Development (8 Month Co-op)</h3>
    <div class="spacer"></div>
    <h3>Jan - Aug 2018</h3>
    <hr>
  </div>

  <div class="row image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\3Dstream_Logo.png" height="180">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <ul>
        <li>Worked on HTML/CSS with frontend team to build a workflow management software which helps companies manage
          their additive manufacturing machines (3D printers)</li>
        <li>Created and maintained documentation (e.g., UML Diagrams)</li>
        <li>Maintained the company's WordPress website and made HTML and CSS modifications where needed</li>
        <li>Corresponded with customers to better understand their needs and turned their needs into software
          requirements</li>
      </ul>
    </div>
  </div>

  <div class="row job-title-and-date">
    <h3 class="margin-right">Product Development (4 Month Co-op)</h3>
    <div class="spacer"></div>
    <h3>May - Aug 2017</h3>
    <hr>
  </div>

  <div class="row" id="last-image-and-job-description">
    <div class="col-10 col-md-4 image">
      <img src="assets\3Dstream_Logo.png" height="180">
    </div>
    <div class="col-0 col-md-1"></div>
    <div class="col-10 col-md-7 job-description">
      <ul>
        <li>Completed UI/UX design (Figma and InVision) for parts of the 3Dstream software, which helps companies manage
          their additive manufacturing machines (3D printers)</li>
        <li>Created documentation (e.g., UML Diagrams)</li>
        <li>Created the company's WordPress website and made HTML and CSS modifications where needed</li>
        <li>Corresponded with customers to better understand their needs and turned their needs into software
          requirements</li>
      </ul>
    </div>
  </div>

</div>
