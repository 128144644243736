<div class="container">

  <div class="row">
    <h1>Skills</h1>
  </div>

  <div class="row">

    <div class="col-12 col-sm-6 col-lg-3">
      <mat-card>
        <i class="fas fa-eye fa-3x"></i>
        <mat-card-header>
          <mat-card-title>Frontend</mat-card-title>
          <mat-card-subtitle>Languages/Tools</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            <mat-list-item>Angular (TypeScript) <br> JavaScript</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>HTML</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>CSS <br> Bootstrap</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>WPF (C#, XAML, .NET)</mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-12 col-sm-6 col-lg-3">
      <mat-card>
        <i class="fas fa-server fa-3x"></i>
        <mat-card-header>
          <mat-card-title>Backend</mat-card-title>
          <mat-card-subtitle>Languages/Tools</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            <mat-list-item>NodeJS (& ExpressJS) <br> Firebase</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>ASP.NET Core (C#, .NET) <br> Entity Framework</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>Java <br> Spring Boot</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>C++</mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-12 col-sm-6 col-lg-3">
      <mat-card>
        <i class="fas fa-database fa-3x"></i>
        <mat-card-header>
          <mat-card-title>Database</mat-card-title>
          <mat-card-subtitle>Languages/Tools</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            <mat-list-item>MongoDB <br> Mongoose</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>MySQL <br> PostgreSQL</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>Firebase Firestore <br> Firebase Realtime DB</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>MS SQL Server</mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-12 col-sm-6 col-lg-3">
      <mat-card>
        <i class="fas fa-laptop-code fa-3x"></i>
        <mat-card-header>
          <mat-card-title>DevOps, Other</mat-card-title>
          <mat-card-subtitle>Languages/Tools</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-list>
            <mat-list-item>Jenkins <br> OpenShift</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>IBM uDeploy <br> JetBrains TeamCity</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>MS Azure <br> Docker</mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>Python <br> Machine Learning</mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

</div>
