<div class="container">

  <div class="row">
    <h1>Contact</h1>
  </div>

  <div class="row" style="margin-bottom: 40px; min-width: 300px;">
    <!-- Text -->
    <div class="col-12 col-md-6 text-column-styling">
      <h2>Hi, nice to meet you...</h2>
      <p>
        If you have a question, comment, or would like to reach out,
        please feel free to contact me using the contact form (or through my contact information, found in my resume).
      </p>

      <div class="row">
        <button mat-flat-button (click)="downloadFile()">Download Resume</button>
      </div>

    </div>
    <!-- /Text -->

    <!-- Form -->
    <div class="col-12 col-md-6" style="margin: 20px auto;">

      <form class="text-center" (ngSubmit)="onSubmit(enteredData);" #enteredData="ngForm">
        <div style="padding: 50px;">

          <h2>Feel free to contact me!</h2>

          <label for="fullName">Full Name <div class="asterisk" matTooltip="This field is required">*</div></label>
          <input class="form-control" type="text" id="fullName" name="fullName" ngModel #fullName="ngModel" required>

          <div *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)" class="alert alert-danger">
            <div *ngIf="fullName.errors.required">
              Name is required.
            </div>
          </div>

          <label for="email">Email <div class="asterisk" matTooltip="This field is required">*</div></label>
          <input class="form-control" type="email" id="email" name="email" ngModel #email="ngModel" required email>

          <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">

            <div *ngIf="email.errors.required">
              Email is required.
            </div>
            <div *ngIf="email.errors.email">
              Email must be in the format of "example@example.com".
            </div>
          </div>

          <label for="subject">Subject <div class="asterisk" matTooltip="This field is required">*</div></label>
          <input class="form-control" type="text" id="subject" name="subject" ngModel #subject="ngModel" required>

          <div *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="alert alert-danger">
            <div *ngIf="subject.errors.required">
              Subject is required.
            </div>
          </div>

          <label for="message">Message <div class="asterisk" matTooltip="This field is required">*</div></label>
          <textarea class="form-control round-0" type="text" id="message" name="message" rows="3" ngModel
            #message="ngModel" required></textarea>

          <div *ngIf="message.invalid && (message.dirty || message.touched)" class="alert alert-danger">
            <div *ngIf="message.errors.required">
              Message is required.
            </div>
          </div>

          <button mat-flat-button type="submit">Send Message</button>
          <div *ngIf="successMessage" class="alert alert-success" id="success-message">
            You have successfully sent a message! I will reply as soon as I can.
          </div>

        </div>
      </form>

    </div>
    <!-- /Form -->

  </div>
</div>

<!--
      Note: ngForm gets access to the underlying form and then upon submitting the form,
          the form gets passed to the onSubmit() method using a reference, #enteredData

      Note: the bootstrap class "form-control" doesn't affect the Angular forms' data, it's just for styling.
          Other bootstrap classes are "text-center" and "round-0"

      Note: the above form is a template-driven form

      Note: "ngModel" registers each input/textarea as a control in the template-driven form
       -->
